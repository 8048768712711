<template>
  <!-- 使用表格设置有一个约定:
    注意：父组件写的时候不要放在引用组件的里边
  -->
  <div>
    <!-- 穿梭框的弹出框 -->
    <Modal
      v-model="chuansuoDialogVisible"
      title="表格设置"
      :width="600"
      :closable="false"
    >
      <el-row>
        <el-col :span="20">
          <div class="chuanSuo">
            <el-transfer
              filterable
              filter-placeholder="请输入表头名称"
              :titles="['未选字段', '已选字段']"
              v-model="value"
              :data="data"
              target-order="unshirt"
              @right-check-change="rightCheck"
            ></el-transfer>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button
            round
            class="el-icon-arrow-up sort_button"
            :disabled="upDisAbled"
            @click="handleToUp"
            style="margin-top:110px"
          ></el-button>
          <el-button
            round
            class="el-icon-arrow-down sort_button"
            :disabled="downDisAbled"
            @click="handleToDown"
            style="margin:10px 0"
          ></el-button>
        </el-col>
      </el-row>
      <div slot="footer">
        <Button @click="cancleSet">取 消</Button>
        <Button type="primary" @click="setTableTitle">确 定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  props: [
    "routerPath",
    "routerPath1",
    "chuansuoDialogVisible",
    "theadDateReal"
  ],
  data() {
    return {
      data: [],
      value: [],
      upDisAbled: true,
      downDisAbled: true,
      tempSelectionKeys: []
    };
  },
  // updated() {
  //   this.showTableTitleDialog();
  // },
  watch: {
    chuansuoDialogVisible: function(val) {
      if (val) {
        this.showTableTitleDialog();
      }
    }
  },
  methods: {
    // 穿梭框右侧选中改变触发的事件
    rightCheck(selectionKeys, changeKeys) {
      this.tempSelectionKeys = selectionKeys;
      if (selectionKeys.length > 0) {
        this.upDisAbled = false;
        this.downDisAbled = false;
      } else {
        this.upDisAbled = true;
        this.downDisAbled = true;
      }
    },
    // 选项上移
    handleToUp() {
      if (this.tempSelectionKeys.length > 1) {
        this.$Message.warning("仅支持单选调顺序");
        return;
      }
      let indexNum = 0;
      for (let i = 0; i < this.tempSelectionKeys.length; i++) {
        indexNum = this.value.indexOf(this.tempSelectionKeys[i]);
        if (indexNum > 0) {
          this.value.splice(indexNum - 1, 0, this.tempSelectionKeys[i]);
          this.value.splice(indexNum + 1, 1);
        }
      }
    },
    // 选项下移
    handleToDown() {
      if (this.tempSelectionKeys.length > 1) {
        this.$Message.warning("仅支持单选调顺序");
        return;
      }
      let indexNum = 0;
      for (let i = 0; i < this.tempSelectionKeys.length; i++) {
        indexNum = this.value.indexOf(this.tempSelectionKeys[i]);
        if (indexNum > -1 && indexNum !== this.value.length - 1) {
          this.value.splice(indexNum + 2, 0, this.tempSelectionKeys[i]);
          this.value.splice(indexNum, 1);
        }
      }
    },
    // 设置表头点击取消
    cancleSet() {
      this.value = [];
      this.$emit("update:chuansuoDialogVisible", false);
    },
    // 显示弹出框穿梭框中的字段
    showTableTitleDialog() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/setFormView",
        method: "get",
        params: {
          router: this.routerPath
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            // 穿梭框
            const data = [];
            const cities = res.data.body.allFields;
            cities.forEach((city, index) => {
              data.push({
                label: city.fieldName,
                key: city.fieldId
              });
            });
            this.data = data;
            for (let i = 0; i < res.data.body.needFields.length; i++) {
              this.value.push(res.data.body.needFields[i].fieldId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设置表格头显示字段
    setTableTitle() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/editForm",
        method: "get",
        params: {
          router: this.routerPath,
          fieldIdList: this.value
        },
        paramsSerializer: (params) => {
          return this.qs.stringify(params, { indices: false });
        }
      })
        .then((res) => {
          new Promise((resolve, reject) => {
            if (res.data.success === 1) {
              this.$Message.success(res.data.msg);
            } else {
              this.$Message.error(res.data.msg);
            }
            this.value.length = 0;
            this.$emit("theadDateReal", this.theadDateReal.length = 0);
            resolve();
          }).then(() => {
            this.noShow();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    noShow() {
      this.$emit("update:chuansuoDialogVisible", false);
      this.$emit("tableDataList");
    }
  }
};
</script>
